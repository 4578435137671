"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusDisplayValue = exports.mapStatus = void 0;
const types_1 = require("../types");
/** Map InternalGatewayStatuses that also contain non public API ExtendedGatewayStatuses to GatewayStatuses */
const mapStatus = (internalStatus) => {
    switch (internalStatus) {
        // Map extended statuses to GatewayStatus
        case types_1.ExtendedGatewayStatus.AWAITING_OWNER_TRANSACTION:
            return types_1.GatewayStatus.IN_REVIEW;
        case types_1.ExtendedGatewayStatus.CONFIRM_OWNER_TRANSACTION:
            return types_1.GatewayStatus.IN_REVIEW;
        case types_1.ExtendedGatewayStatus.CHAIN_TIMEOUT_ERROR:
            return types_1.GatewayStatus.ERROR;
        case types_1.ExtendedGatewayStatus.CHAIN_TRANSACTION_ERROR:
            return types_1.GatewayStatus.ERROR;
        default:
            // otherwise return GatewayStatus
            return internalStatus;
    }
};
exports.mapStatus = mapStatus;
/** Return a display value for the given InternalGatewayStatus which includes both ExtendedGatewayStatuses and GatewayStatuses */
const statusDisplayValue = (internalStatus) => {
    return types_1.ExtendedGatewayStatus[internalStatus] || types_1.GatewayStatus[internalStatus];
};
exports.statusDisplayValue = statusDisplayValue;
