"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ownerSignsEnabled = exports.makeConfig = exports.getGatekeeperEndpoint = exports.GATEKEEPER_ENDPOINTS = void 0;
const solanaVersionedEndpoint = 'v1/token/solana';
exports.GATEKEEPER_ENDPOINTS = {
    local: `http://localhost:3001/local/${solanaVersionedEndpoint}`,
    test: `https://dev-gatekeeper-api.civic.com/${solanaVersionedEndpoint}`,
    dev: `https://dev-gatekeeper-api.civic.com/${solanaVersionedEndpoint}`,
    preprod: `https://preprod-gatekeeper-api.civic.com/${solanaVersionedEndpoint}`,
    prod: `https://gatekeeper-api.civic.com/${solanaVersionedEndpoint}`,
};
const getGatekeeperEndpoint = (stage) => {
    const endpoint = exports.GATEKEEPER_ENDPOINTS[stage];
    if (!endpoint) {
        throw new Error(`Invalid stage ${stage}`);
    }
    return endpoint;
};
exports.getGatekeeperEndpoint = getGatekeeperEndpoint;
const makeConfig = (clusterUrl, cluster) => {
    return {
        cluster,
        commitment: 'confirmed',
        // this map instructs the POWO library to use clusterUrl
        // to connect to the solana network. This avoids rate limiting issues with using the default
        // public urls
        supportedClusterUrls: {
            [cluster]: clusterUrl,
        },
        recentBlockCheck: false,
        broadcastCheck: false,
    };
};
exports.makeConfig = makeConfig;
/**
 * Return the value of owner signs enabled based on the deprecated broadcastTransaction flag as well as gatekeeperSendsTransaction.
 * If gatekeeperSendsTransaction is defined, then ownerSings is the inverse of its value
 * If gatekeeperSendsTransaction is not defined, then ownerSings is the inverse of broadcastTransaction
 * If neither are defined, ownerSigns is enabled
 */
const ownerSignsEnabled = ({ broadcastTransaction, gatekeeperSendsTransaction, }) => {
    const gatekeeperSendsTransactionSpecified = gatekeeperSendsTransaction !== undefined;
    return gatekeeperSendsTransactionSpecified ? !gatekeeperSendsTransaction : !(broadcastTransaction || false);
};
exports.ownerSignsEnabled = ownerSignsEnabled;
